import React from 'react';
import { createReactEditorJS } from 'react-editor-js';
import Header from '@editorjs/header';
import Paragraph from '@editorjs/paragraph';
import List from '@editorjs/list';
import Image from '@editorjs/image';
import Embed from '@editorjs/embed';
import RawTool from '@editorjs/raw';
import Quote from '@editorjs/quote';
import Marker from '@editorjs/marker';
import LinkTool from '@editorjs/link';
import CodeTool from '@editorjs/code';
import Delimiter from '@editorjs/delimiter';
import Table from '@editorjs/table';
import Warning from '@editorjs/warning';
import Checklist from '@editorjs/checklist';
import AttachesTool from '@editorjs/attaches';
import InlineCode from '@editorjs/inline-code';

// yarn add @editorjs/quote @editorjs/marker @editorjs/link @editorjs/code @editorjs/delimiter @editorjs/table @editorjs/warning @editorjs/checklist @editorjs/attaches @editorjs/inline-code

const ReactEditorJS = createReactEditorJS();

const EditorRenderer: React.FC<{ id: string; data: any }> = ({ id, data }) => {
  return (
      <ReactEditorJS holder="editorjs"
        readOnly
        tools={{
          header: Header,
          paragraph: Paragraph,
          list: List,
          quote: Quote,
          image: {
            class: Image,
          },
          embed: {
            class: Embed as any,
            config: {
              services: {
                youtube: true,
                vimeo: true,
              },
            },
          },
          raw: RawTool,
          marker: Marker,
          linkTool: LinkTool,
          code: CodeTool,
          delimiter: Delimiter,
          table: Table,
          warning: Warning,
          checklist: Checklist,
          attaches: {
              class: AttachesTool,
          },
          inlineCode: InlineCode,
        }}
        defaultValue={data}
      />
  );
};

export default EditorRenderer;