import React from 'react';
import { Link } from 'react-router-dom';
import { Token } from '../../../api/tokens/types';
import { Collection } from 'api/collections/types';
import { useTranslation } from 'react-i18next';
import { shortenText } from 'utils/helpers/formatHelper';
import LazyImage from 'components/lazy-image';

interface CollectionItemCardProps {
    token: Token;
    collection?: Collection;
}

const CollectionItemCard  : React.FC<CollectionItemCardProps> = ({ token, collection }) => {
    const { t } = useTranslation();
    collection = collection ?? token.collection;
    return token && collection && (
        <div className="col mt-4 pt-2">
            <div className="card nft-items nft-primary rounded-md shadow overflow-hidden mb-1 p-3">
                
                <div className="nft-image rounded-md position-relative overflow-hidden">
                    <Link to={`/collection/${collection.slug}/items/${token.identifier}`}><LazyImage src={token.display_image_permalink} className="img-fluid" alt={token.identifier} height='328px'/></Link>
                </div>

                <div className="card-body content position-relative p-0 mt-3">
                    <Link to={`/collection/${collection.slug}/items/${token.identifier}`} className="title text-dark h6" title={token.name}>{shortenText(token.name, 26, 24)}</Link>

                    <div className="text-center mt-3">
                        <Link to={`/collection/${collection.slug}/items/${token.identifier}`} className="btn btn-l btn-pills btn-primary"><i className="mdi mdi-eye fs-5 me-2"></i> {t('common.buttons.view_more.label')}</Link>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default CollectionItemCard;