import React, { useEffect, Suspense } from 'react'
import { useDispatch } from 'react-redux';
import { fetchCategoriesMenu, setCategoriesMenuFromCache } from './store/categories/categories.slice';
import { AppDispatch } from './store/store';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { FiltersYesNo } from './enums/filters';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './assets/scss/style-dark.scss'
import './assets/css/materialdesignicons.min.css'

import Index from './pages/index';
import CategoryDetail from 'pages/category/category-detail';
import CollectionDetail from 'pages/collection/collection-detail';
import ProfileDetail from 'pages/profile/profile-detail';
import { Web3ModalProvider } from 'context/Web3ModalProvider';
import { SnackbarProvider } from 'notistack';
import { AppContextProvider } from 'context/AppContextProvider';
import PrivateRoute from 'components/private-route';
import AccountDetail from 'pages/account/account-detail';
import AccountNotification from 'pages/account/notifications';
import { PusherProvider } from 'context/PusherProvider';
import ItemDetail from 'pages/collection/item-detail';
import { clearCache, getCacheItem, setCacheItem } from 'utils/helpers/cacheHelper';
import { I18nextProvider } from 'react-i18next';
import i18n from './utils/i18n/i18n';
import { HelmetProvider } from 'react-helmet-async';
import NotFound from 'pages/spicel/notfound';
import Maintenance from 'pages/spicel/maintenance';
import BlogDetail from 'pages/blog/blog-detail';
import Blogs from 'pages/blog/blogs';

function App() {
  const dispatch = useDispatch<AppDispatch>();
  const isMaintenanceMode = (process.env.REACT_APP_MAINTENANCE !== undefined && process.env.REACT_APP_MAINTENANCE === 'true' ? true : false);

  useEffect(() => {
    // 24hs
    clearCache({ prefix: 'categoriesMenuList', duration: 24 * 60 * 60 * 1000 });

    const cachedCategories = getCacheItem('categoriesMenuList');
    if (cachedCategories) {
      dispatch(setCategoriesMenuFromCache(cachedCategories));
    } else {
      dispatch(fetchCategoriesMenu({ show_menu: FiltersYesNo.YES })).then((response) => {
        if (response.payload) {
          setCacheItem('categoriesMenuList', response.payload);
        }
      });
    }
  }, [dispatch]);

  /*
  useEffect(() => {
    window.myDebugFunction = (data: any) => {
      console.log('Función ejecutada desde la consola', data);
      dispatch(updateAllCollections(data.data.collection));
    };
  }, [dispatch]);
  */

  return (
    <HelmetProvider>
      <SnackbarProvider maxSnack={3}>
        <Web3ModalProvider>
          <AppContextProvider>
            <PusherProvider>
              <Suspense fallback={<div>Loading...</div>}>
                <I18nextProvider i18n={i18n}>
                  <BrowserRouter>
                  {isMaintenanceMode ? (
                    <Maintenance />
                  ) : (
                    <Routes>
                      <Route path="/" element={<Index/>}/>
                      <Route path="/category/:slug" element={<CategoryDetail />} />
                      <Route path="/collection/:identifier" element={<CollectionDetail />} />
                      <Route path="/collection/:identifier/items/:token_identifier" element={<ItemDetail />} />
                      <Route path="/profile/:identifier" element={<ProfileDetail />}>
                      </Route>
                      
                      <Route path="/blog" element={<Blogs />} />
                      <Route path="/blog/:slug" element={<BlogDetail />} />
                      <Route element={<PrivateRoute />}>
                        <Route path="/account">
                          <Route path="settings" element={<AccountDetail />} />
                          <Route path="settings/notifications" element={<AccountNotification />} />
                        </Route>
                      </Route>
                      <Route path="*" element={<NotFound />} />
                    </Routes>
                  )}
                  </BrowserRouter>
                </I18nextProvider>
              </Suspense>
            </PusherProvider>
          </AppContextProvider>
        </Web3ModalProvider>
      </SnackbarProvider>
    </HelmetProvider>
  );
}

export default App;
