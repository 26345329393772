import React, { useState } from 'react';
import { useCollection } from '../../context/collection/collection-provider';
import Navbar from 'components/navbar';
import { Link  } from 'react-router-dom';
import LazyImage from 'components/lazy-image';
import CollectionMint from './collection-mint';
import ExpandableText from 'components/expandable-text';
import { formatSalePrice } from 'utils/helpers/formatHelper';
import { getNextStage } from 'utils/helpers/collectionHelper';
import CollectionMintSchedule from './collection-mint-schedule';
import CollectionItems from './items/collection-items';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const CollectionHeader = () => {
    const { t } = useTranslation();
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let [activeTab, setActiveTab] = useState(1)
    let [activeTabCollection, setActiveTabCollection] = useState(1)
    const collection = useCollection();
    /*
    const location = useLocation();
    const path = location.pathname;

    const isActiveNav = (segment:string) => {
        if (segment === 'items') {
            return !path.includes('/details') && !path.includes('/activity');
        }
        return path.includes(`/${segment}`);
    };
    */

    if (!collection){
        return <div>Loading...</div>;
    }

    // const totalAvailable = getTotalAvailable(collection?.contracts || []);

    const contract = collection?.contracts && collection?.contracts.length > 0 ? collection.contracts[0] : null;
    
    const stage = getNextStage(collection?.stages || [], userTimeZone);

    // const getRandomNumber = () => Math.floor(Math.random() * 2) + 1;
    const getRandomNumber = () => 2;
    const displayTheme = getRandomNumber();

    const currentUrl = `${window.location.origin}/collection/${collection?.slug}`;

    return collection && contract && (
    <>
        <Helmet>
            <title>{collection.name} - {process.env.REACT_APP_NAME}</title>
            <meta content={collection.name +' | '+ process.env.REACT_APP_NAME} property="og:title"/>
            <meta content="website" property="og:type"/>
            <meta content={collection.logo_permalink} property="og:image"/>
            <meta content={currentUrl} property="og:url"/>
            <meta content={collection.description} property="og:description"/>

            <meta content={collection.name} name="twitter:title"/>
            <meta content={'Created by ' + collection.owner.name} name="twitter:author"/>
            <meta content={collection.logo_permalink} name="twitter:image"/>
            <meta content={collection.name} name="twitter:image:alt"/>
            <meta content={currentUrl + "/?utm_medium=organic&amp;utm_source=twitter&amp;utm_campaign=demo"} name="twitter:url"/>
            <meta content={'View on ' + process.env.REACT_APP_NAME} name="twitter:cta"/>
            <link href={currentUrl} rel="canonical"/>
            <meta content={collection.description} name="description"/>

            <script type="application/ld+json">
            {JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'WebSite',
                name: process.env.REACT_APP_NAME,
                url: window.location.origin,
            })}
            </script>
            <script type="application/ld+json">
            {JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'Organization',
                name: process.env.REACT_APP_NAME,
                url: window.location.origin,
                logo: window.location.origin + '/assets/images/logo-light.webp',
            })}
            </script>
            <script type="application/ld+json">
            {JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'Product',
                name: collection.name,
                image: collection.logo_permalink,
                description: collection.description,
                creator: {
                '@type': 'Person',
                name: collection.owner.name,
                },
                url: currentUrl,
            })}
            </script>
        </Helmet>
        <Navbar navlight={true}/>
        {displayTheme === 1 && (
            <section className="bg-half-100 p-0 d-table w-100" style={{backgroundImage:`url("${collection.banner_image_permalink}")` , backgroundPosition:'bottom'}}>
                <div className="bg-overlay bg-gradient-overlay-2"></div>
                <div className="container h-100 d-flex flex-column justify-content-end mt-100 mb-3">
                    <div className="row mt-5">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-4">
                            <div className="title-heading">
                                <h5 className="heading fw-semibold sub-heading text-white title-dark">{collection.name}</h5>
                                <div className="row">
                                    <div className="col-12 d-flex align-items-center">
                                        <LazyImage src={collection.owner.profile_photo_url} className="avatar avatar-md-sm shadow-md rounded-pill" alt={collection.owner.username} />
                                        <div className="ms-3">
                                            <h6 className="mb-0">
                                                <Link to={`/profile/${collection.owner.username}`} className="name">{collection.owner.name}</Link>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 d-flex align-items-end">
                            <div className="w-100 overflow-hidden">
                                <div className="d-flex justify-content-start overflow-auto" style={{ whiteSpace: 'nowrap' }}>
                                    <a className="text-decoration-none text-primary me-4" href="/collection/persona-base/activity">
                                        <div className="d-flex flex-column align-items-start">
                                            <span className="text-white opacity-80">Total volume</span>
                                            <span className="font-weight-bold text-white">8 ETH</span>
                                        </div>
                                    </a>
                                    <a className="text-decoration-none text-primary me-4" href="/collection/persona-base?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[toggles][0]=BUY_NOW">
                                        <div className="d-flex flex-column align-items-start">
                                            <span className="text-white opacity-80">Floor price</span>
                                            <span className="font-weight-bold text-white">0.175 ETH</span>
                                        </div>
                                    </a>
                                    <a className="text-decoration-none text-primary me-4" href="/collection/persona-base/offers">
                                        <div className="d-flex flex-column align-items-start">
                                            <span className="text-white opacity-80">Best offer</span>
                                            <span className="font-weight-bold text-white">0.0755 WETH</span>
                                        </div>
                                    </a>
                                    <a className="text-decoration-none text-primary me-4" href="/collection/persona-base/activity?search[eventTypes][0]=AUCTION_CREATED">
                                        <div className="d-flex flex-column align-items-start">
                                            <span className="text-white opacity-80">Listed</span>
                                            <span className="font-weight-bold text-white">0.6%</span>
                                        </div>
                                    </a>
                                    <span>
                                        <div className="d-flex flex-column align-items-start">
                                            <span className="text-white opacity-80">Owners</span>
                                            <span className="font-weight-bold text-white">27</span>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )}

        {displayTheme === 2 && (
        <div className="collection-header">
            <section className="bg-half-174 d-table w-100" style={{backgroundImage:`url('${collection.banner_image_permalink}')`, backgroundPosition:'center'}}>
                <div className="container">
                    <div className="row mt-5">
                        <div className="col py-5 py-sm-0 my-5 my-sm-0">
                            <div className="play-icon"></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section pb-0">
                <div className="w-100">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="features-absolute p-4 pt-4">
                                <div className="row justify-content-center">
                                    <div className="col">
                                        <div style={{zIndex:'2'}} className="d-flex justify-content-between align-items-center position-absolute title-heading w-100">
                                            <h5 className="heading fw-semibold sub-heading text-white title-dark mx-3 d-none d-md-block">{collection.name}</h5>
                                            <ul className="list-unstyled social-icon foot-social-icon me-3">
                                                {collection.website_url && (
                                                <li className="list-inline-item lh-1">
                                                    <a href={collection.website_url} target="_blank" rel="noopener noreferrer" className="rounded" style={{backgroundColor:'#3c4858', borderColor: '#3c4858'}}>
                                                    <i className="uil uil-dribbble"></i>
                                                    </a>
                                                </li>
                                                )}
                                                {collection.twitter_username && (
                                                <li className="list-inline-item lh-1">
                                                    <a href={`https://twitter.com/${collection.twitter_username}`} target="_blank" rel="noopener noreferrer" className="rounded" style={{backgroundColor:'#3c4858', borderColor: '#3c4858'}}>
                                                    <i className="uil uil-twitter"></i>
                                                    </a>
                                                </li>
                                                )}
                                            </ul>
                                        </div>
                                        <div style={{zIndex:'1'}} className="text-center">
                                            <div className="profile-pic">
                                                <div className="position-relative d-inline-block">
                                                    <Link to={`/profile/${collection.owner.username}`}>
                                                        <LazyImage src={collection.owner.profile_photo_url} className="avatar avatar-medium img-thumbnail rounded-pill shadow-sm" id="profile-image" alt={collection.owner.username} height='106px'/>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="content mt-3">
                                                <Link to={`/profile/${collection.owner.username}`}>
                                                    <h5 className="mb-3">{collection.owner.name}</h5>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <section className="bg-item-detail d-table w-100 pt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="sticky-bar">
                                <LazyImage src={collection.logo_permalink} className="img-fluid rounded-md shadow" alt={collection.name} height='546px'/>
                            </div>
                        </div>

                        <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <div className="ms-lg-5">
                                <div className="title-heading">
                                    <h4 className="h3 fw-bold mb-0">{collection.name}</h4>
                                </div>

                                {contract.is_soldout ? (
                                <>
                                    <div className="row">
                                        <div className="col-md-6 mt-4 pt-2">
                                            <h4 className="mb-0">{t('collection.contracts.labels.soldout')}</h4>
                                        </div>
                                    </div>
                                </>
                                ) : (
                                <>
                                    <div className="row">
                                        {stage && (
                                        <div className="col-md-6 mt-4 pt-2">
                                            <h6>{stage.name}</h6>
                                            <h4 className="mb-0">{formatSalePrice(stage.sale_price)}</h4>
                                        </div>
                                        )}
                                    </div>
                                    <CollectionMint collection={collection} />
                                </>
                                )}

                                <div className="row mt-4 pt-2">
                                    <div className="col-12">
                                        <ul className="nav nav-tabs border-bottom">
                                            <li className="nav-item">
                                                <button className={`nav-link ${activeTab === 1 ? 'active' : ''}`} onClick={()=>setActiveTab(1)}>{t('collection.fields.details')}</button>
                                            </li>
                                            <li className="nav-item">
                                                <button className={`nav-link ${activeTab === 2 ? 'active' : ''}`} onClick={()=>setActiveTab(2)}>{t('collection.fields.schedule')}</button>
                                            </li>
                                        </ul>
                                        <div className="tab-content mt-4 pt-2">
                                            {activeTab === 1 && (
                                            <div className="tab-pane fade show active">
                                                <ExpandableText text={collection.description} limit={100} initiallyExpanded={true} />
                                            </div>
                                            )}
                                            {activeTab === 2 && (
                                                <div className="tab-pane fade show active">
                                                    <CollectionMintSchedule collection={collection} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            

            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <ul className="nav nav-tabs border-bottom">
                            <li className="nav-item">
                                <button className={`nav-link ${activeTabCollection === 1 ? 'active' : ''}`} onClick={()=>setActiveTabCollection(1)}>{t('collection.fields.items')} ({contract.total_minted})</button>
                            </li>
                            
                            <li className="nav-item">
                                <button className={`nav-link ${activeTabCollection === 2 ? 'active' : ''}`} onClick={()=>setActiveTabCollection(2)}>{t('collection.fields.details')}</button>
                            </li>
                        </ul>
                        <div className="tab-content mt-4 pt-2">
                            {activeTabCollection === 1 && (
                            <div className="tab-pane fade show active">
                                <CollectionItems collection={collection} />
                            </div>
                            )}
                            {activeTabCollection === 2 && (
                            <div className="tab-pane fade show active">
                                <h5 className="mb-4">{collection.name}</h5>
                                <ExpandableText text={collection.description} limit={100} initiallyExpanded={true} />
                            </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )}
    </>
    );
};

export default CollectionHeader;