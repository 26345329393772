import { isWithinInterval } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { CollectionContract, CollectionMintStage } from '../../api/collections/types';

export const getTotalAvailable = (contracts?: CollectionContract[]): number => {
    if (!contracts || contracts.length === 0) {
      return 0;
    }
    const firstContract = contracts[0];
    return firstContract.total_available;
};

export const getIsSoldout = (contracts?: CollectionContract[]): boolean => {
  if (!contracts || contracts.length === 0) {
    return false;
  }
  const firstContract = contracts[0];
  return firstContract.is_soldout;
};

export const getCurrentStage = (stages: CollectionMintStage[], timeZone: string): CollectionMintStage | null => {
    const now = new Date();
    const userTime = toZonedTime(now, timeZone);
  
    return (
      stages.find(stage => {
        const start = toZonedTime(new Date(stage.start_at), timeZone);
        const end = stage.end_at ? toZonedTime(new Date(stage.end_at), timeZone) : null;
        return userTime >= start && (!end || userTime <= end);
      }) || null
    );
};

export const getNextStage = (stages: CollectionMintStage[], timeZone: string): CollectionMintStage | null => {
  const now = new Date();
  const userTime = toZonedTime(now, timeZone);

  return (
    stages
      .filter(stage => {
        const start = toZonedTime(new Date(stage.start_at), timeZone);
        const end = stage.end_at ? toZonedTime(new Date(stage.end_at), timeZone) : null;
        return start >= userTime || (!end || end >= userTime);
      })
      .sort((a, b) => new Date(a.start_at).getTime() - new Date(b.start_at).getTime())[0] || null
  );
};