import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AsyncThunkConfig, RootState } from '../store';
import { Collection, CollectionListResponse, FetchParams, CollectionTraitsCategories, CollectionTraitsCategoriesListResponse } from '../../api/collections/types';
import { OrderBy } from '../../api/collections/enums';

import {
    AddActionCases,
    GenericSliceState,
    HandleAsyncActionError,
} from '../../utils/helpers/reduxToolkitHelper';

export interface CollectionsState extends GenericSliceState {
    featuredCollections: CollectionListResponse | null;
    mintingNowCollections: CollectionListResponse | null;
    comingSoonCollections: CollectionListResponse | null;
    categoryCollections: CollectionListResponse | null;
    searchCollections: CollectionListResponse | null;
    collections: CollectionListResponse | null;
    collection: Collection | null;
    orderBy: string;
    traits_categories: CollectionTraitsCategoriesListResponse | null;

    isLoadingFeatured: boolean;
    errorCodeFeatured: string | null;
    errorMessageFeatured: string;
    isLoadingMintingNow: boolean;
    errorCodeMintingNow: string | null;
    errorMessageMintingNow: string;
    isLoadingComingSoon: boolean,
    errorCodeComingSoon: string | null;
    errorMessageComingSoon: string;
    isLoadingTraits: boolean,
    errorCodeTraits: string | null;
    errorMessageTraits: string;
    isLoadingCategory: boolean,
    errorCodeCategory: string | null;
    errorMessageCategory: string;
    isLoadingSearch: boolean;
    errorCodeSearch: string | null;
    errorMessageSearch: string;

    isLoading: boolean;
    errorCode: string | null;
    errorMessage: string;
}

export const initialState: CollectionsState = {
    featuredCollections: null,
    mintingNowCollections: null,
    comingSoonCollections: null,
    categoryCollections: null,
    searchCollections: null,
    collections: null,
    collection: null,
    orderBy: OrderBy.NAME,
    traits_categories: null,
    
    isLoadingFeatured: false,
    errorCodeFeatured: null,
    errorMessageFeatured: '',
    
    isLoadingMintingNow: false,
    errorCodeMintingNow: null,
    errorMessageMintingNow: '',

    isLoadingComingSoon: false,
    errorCodeComingSoon: null,
    errorMessageComingSoon: '',

    isLoadingTraits: false,
    errorCodeTraits: null,
    errorMessageTraits: '',

    isLoadingCategory: false,
    errorCodeCategory: null,
    errorMessageCategory: '',

    isLoading: false,
    errorCode: null,
    errorMessage: '',

    isLoadingSearch: false,
    errorCodeSearch: null,
    errorMessageSearch: '',
};

// ------------- //
// Async Actions //
// ------------- //

export const getByIdentifier = createAsyncThunk<Collection, { identifier: string, params: FetchParams }, AsyncThunkConfig>(
    'collections/getByIdentifier',
    async ({ identifier, params }, thunkAPI) => {
        try {
            const result = await thunkAPI.extra.collections.getByIdentifier(identifier, params);
            return result;
        } catch (err: any) {
            return HandleAsyncActionError(err, thunkAPI);
        }
    },
);

export const fetchAll = createAsyncThunk<CollectionListResponse, FetchParams, AsyncThunkConfig>(
    'collections/fetchAll',
    async (params: FetchParams = { order_by: OrderBy.NAME }, thunkAPI) => {
        try {
            const result = await thunkAPI.extra.collections.fetchAll(params);
            return result;
        } catch (err: any) {
            return HandleAsyncActionError(err, thunkAPI);
        }
    },
);

export const fetchSearch = createAsyncThunk<CollectionListResponse, FetchParams, AsyncThunkConfig>(
    'collections/fetchSearch',
    async (params: FetchParams = { order_by: OrderBy.NAME }, thunkAPI) => {
        try {
            const result = await thunkAPI.extra.collections.fetchAll(params);
            return result;
        } catch (err: any) {
            return HandleAsyncActionError(err, thunkAPI);
        }
    },
);

export const fetchCursor = createAsyncThunk<CollectionListResponse, { cursor: string }, AsyncThunkConfig>(
    'collections/fetchCursor',
    async ({ cursor }, thunkAPI) => {
        try {
            const result = await thunkAPI.extra.collections.fetchCursor(cursor);
            return result;
        } catch (err: any) {
            return HandleAsyncActionError(err, thunkAPI);
        }
    },
);

export const fetchFeaturedCollections = createAsyncThunk<CollectionListResponse, FetchParams, AsyncThunkConfig>(
    'collections/fetchFeatured',
    async (params: FetchParams = { order_by: OrderBy.NAME }, thunkAPI) => {
        try {
            const result = await thunkAPI.extra.collections.fetchAll(params);
            return result;
        } catch (err: any) {
            return HandleAsyncActionError(err, thunkAPI);
        }
    },
);

export const fetchMintingNowCollections  = createAsyncThunk<CollectionListResponse, FetchParams, AsyncThunkConfig>(
    'collections/fetchMintingNow',
    async (params: FetchParams = { order_by: OrderBy.NAME }, thunkAPI) => {
        try {
            const result = await thunkAPI.extra.collections.fetchAll(params);
            return result;
        } catch (err: any) {
            return HandleAsyncActionError(err, thunkAPI);
        }
    },
);

export const fetchComingSoonCollections  = createAsyncThunk<CollectionListResponse, FetchParams, AsyncThunkConfig>(
    'collections/fetchComingSoon',
    async (params: FetchParams = { order_by: OrderBy.NAME }, thunkAPI) => {
        try {
            const result = await thunkAPI.extra.collections.fetchAll(params);
            return result;
        } catch (err: any) {
            return HandleAsyncActionError(err, thunkAPI);
        }
    },
);

export const fetchCategoryCollections  = createAsyncThunk<CollectionListResponse, FetchParams, AsyncThunkConfig>(
    'collections/fetchByCategory',
    async (params: FetchParams = { order_by: OrderBy.NAME }, thunkAPI) => {
        try {
            const result = await thunkAPI.extra.collections.fetchAll(params);
            return result;
        } catch (err: any) {
            return HandleAsyncActionError(err, thunkAPI);
        }
    },
);

export const fetchTraits  = createAsyncThunk<CollectionTraitsCategoriesListResponse, {identifier: string}, AsyncThunkConfig>(
    'collections/fetchTraits',
    async ({ identifier}, thunkAPI) => {
        try {
            const result = await thunkAPI.extra.collections.fetchTraits(identifier);
            return result;
        } catch (err: any) {
            return HandleAsyncActionError(err, thunkAPI);
        }
    },
);


// ----- //
// Slice //
// ----- //

export const collectionsSlice = createSlice({
    name: 'collections',
    initialState,
    reducers: {
        // -------------- //
        // Sync Reducers //
        // -------------- //

        clearCollections: (state, action: PayloadAction<void>) =>
            Object.assign(state, initialState),

        clearCategoryCollections: (state) => {
            state.categoryCollections = null;
        },
        setOrderBy: (state, action: PayloadAction<string>) => {
            state.orderBy = action.payload;
        },

        updateAllCollections: (state, action: PayloadAction<Collection>) => {
            // const updatedCollection = action.payload;

            // @debug
            // console.log('FILE: collections.slice.ts METHOD: updateAllCollections VARS: action.payload', action.payload);
            // console.log('FILE: collections.slice.ts METHOD: updateAllCollections VARS: state.collections', state.collections);
            // console.log('FILE: collections.slice.ts METHOD: updateAllCollections VARS: state.featuredCollections', state.featuredCollections);
            // console.log('FILE: collections.slice.ts METHOD: updateAllCollections VARS: state.mintingNowCollections', state.mintingNowCollections);
            // console.log('FILE: collections.slice.ts METHOD: updateAllCollections VARS: state.comingSoonCollections', state.comingSoonCollections);
            // console.log('FILE: collections.slice.ts METHOD: updateAllCollections VARS: state.categoryCollections', state.categoryCollections);
            // console.log('FILE: collections.slice.ts METHOD: updateAllCollections VARS: state.collection', state.collection);
        
            const updatedCollection = action.payload;

            const updateCollectionContracts = (collection: any) => {
                if (collection.contracts && collection.contracts.length > 0 && updatedCollection && updatedCollection.contracts && updatedCollection.contracts.length > 0) {
                    collection.contracts = collection.contracts.map((contract: any) => {
                      const updatedContract = updatedCollection.contracts?.find((uc: any) => uc.id === contract.id);
                      return updatedContract ? { ...contract, ...updatedContract } : contract;
                    });
                }
            };

            // Recorre las colecciones y actualiza
            if (state.collections) {
                const index = state.collections.data.findIndex(c => c.id === updatedCollection.id);
                if (index !== -1) {
                updateCollectionContracts(state.collections.data[index]);
                }
            }

            if (state.featuredCollections) {
                const index = state.featuredCollections.data.findIndex(c => c.id === updatedCollection.id);
                if (index !== -1) {
                updateCollectionContracts(state.featuredCollections.data[index]);
                }
            }

            if (state.mintingNowCollections) {
                const index = state.mintingNowCollections.data.findIndex(c => c.id === updatedCollection.id);
                if (index !== -1) {
                updateCollectionContracts(state.mintingNowCollections.data[index]);
                }
            }

            if (state.comingSoonCollections) {
                const index = state.comingSoonCollections.data.findIndex(c => c.id === updatedCollection.id);
                if (index !== -1) {
                updateCollectionContracts(state.comingSoonCollections.data[index]);
                }
            }

            if (state.categoryCollections) {
                const index = state.categoryCollections.data.findIndex(c => c.id === updatedCollection.id);
                if (index !== -1) {
                updateCollectionContracts(state.categoryCollections.data[index]);
                }
            }

            if (state.collection && state.collection.id === updatedCollection.id) {
                updateCollectionContracts(state.collection);
            }
        },
    },
    extraReducers: (builder) => {
        // -------------- //
        // Async Reducers //
        // -------------- //

        AddActionCases(
            builder,
            getByIdentifier,
            (state, action) => {
                state.collection = action.payload;
            },
            (state, action) => {
                state.collection = null;
            },
            (state, action) => {},
        );

        AddActionCases(
            builder,
            fetchAll,
            (state, action) => {
                state.collections = action.payload;
            },
            (state, action) => {
                state.collections = null;
            },
            (state, action) => {},
        );

        AddActionCases(
            builder,
            fetchCursor,
            (state, action) => {
                if (state.collections) {
                    state.collections.data = [...state.collections.data, ...action.payload.data];
                    state.collections.links = action.payload.links;
                    state.collections.meta = action.payload.meta;
                } else {
                    state.collections = action.payload;
                }
            },
            (state, action) => {},
            (state, action) => {},
        );
        
        AddActionCases(
            builder,
            fetchFeaturedCollections,
            (state, action) => {
                state.featuredCollections = action.payload;
            },
            (state, action) => {
                state.featuredCollections = null;
            },
            (state, action) => {},
            'Featured'
        );

        AddActionCases(
            builder,
            fetchMintingNowCollections,
            (state, action) => {
                state.mintingNowCollections = action.payload;
            },
            (state, action) => {
                state.mintingNowCollections = null;
            },
            (state, action) => {},
            'MintingNow'
        );

        AddActionCases(
            builder,
            fetchComingSoonCollections,
            (state, action) => {
                state.comingSoonCollections = action.payload;
            },
            (state, action) => {
                state.comingSoonCollections = null;
            },
            (state, action) => {},
            'ComingSoon'
        );

        AddActionCases(
            builder,
            fetchCategoryCollections,
            (state, action) => {
                state.categoryCollections = action.payload;
            },
            (state, action) => {
                state.categoryCollections = null;
            },
            (state, action) => {},
            'Category'
        );

        AddActionCases(
            builder,
            fetchTraits,
            (state, action) => {
                state.traits_categories = action.payload;
            },
            (state, action) => {
                state.traits_categories = null;
            },
            (state, action) => {},
            'Traits'
        );

        AddActionCases(
            builder,
            fetchSearch,
            (state, action) => {
                state.searchCollections = action.payload;
            },
            (state, action) => {
                state.searchCollections = null;
            },
            (state, action) => {},
            'Search'
        );
    },
});

// ------------ //
// Sync Actions //
// ------------ //
export const { clearCollections, clearCategoryCollections, setOrderBy, updateAllCollections } = collectionsSlice.actions;

export const collectionsSelector = (state: RootState) => state.collections;
export default collectionsSlice.reducer;
