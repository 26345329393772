import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
    GenericSliceState,
} from '../../utils/helpers/reduxToolkitHelper';

export interface LanguageState extends GenericSliceState {
    language: string;
}

export const initialState: LanguageState = {
    isLoading: false,
    errorCode: null,
    errorMessage: '',

    language: localStorage.getItem('language') || 'en',
};

// ------------- //
// Async Actions //
// ------------- //



// ----- //
// Slice //
// ----- //

export const languageSlice = createSlice({
    name: 'language',
    initialState,
    reducers: {
        // -------------- //
        // Sync Reducers //
        // -------------- //

        clearLanguageSlice: (state, action: PayloadAction<void>) => Object.assign(state, initialState),

        setLanguage: (state, action: PayloadAction<string>) => {
            state.language = action.payload;
            localStorage.setItem('language', action.payload);
        },
    },
    extraReducers: builder => {
        // -------------- //
        // Async Reducers //
        // -------------- //
    },
});

// ------------ //
// Sync Actions //
// ------------ //
export const {
    clearLanguageSlice,
    setLanguage,
} = languageSlice.actions;

export const languageSliceSelector = (state: RootState) => state.language;
export default languageSlice.reducer;
