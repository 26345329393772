import React,{useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Outlet, useNavigate  } from 'react-router-dom';
import { AppDispatch, RootState } from '../../store/store';
import { getByIdentifier } from '../../store/collections/collections.slice';
import CollectionProvider from '../../context/collection/collection-provider';
import CollectionHeader from '../../components/collection/collection-header';
import Footer from '../../components/footer';

const CollectionDetail = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { collection, errorCode } = useSelector((state: RootState) => state.collections);
    const { identifier } = useParams<{ identifier: string }>();
    
    useEffect(() => {
        if (identifier) {
            dispatch(getByIdentifier({ identifier: identifier, params: {collection_fields: 'owner,categories,stages,contracts'} }));
        }
    }, [dispatch, identifier]);

    useEffect(() => {
        if (errorCode) {
            navigate('/404', { replace: true });
        }
    }, [errorCode, navigate]);

    return collection && (
        <CollectionProvider collection={collection}>
            <CollectionHeader />
            <Outlet />
            <div className="mt-100"></div>
            <Footer />
        </CollectionProvider>
    )
};

export default CollectionDetail;