import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateSocialPlatform } from '../../store/users/users.slice';
import { fetchAll } from '../../store/social_platforms/social_platforms.slice';
import { User } from 'api/users/types';
import { SocialPlatform } from 'api/social_platforms/types';
import { AppDispatch, RootState } from 'store/store';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { setUser } from 'store/auth/auth.slice';
import { Spinner } from 'react-bootstrap';


interface AccountEditSocialConnectionFormProps {
    user: User | null;
}

const AccountEditSocialConnectionForm: React.FC<AccountEditSocialConnectionFormProps> = ({ user }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const { isLoading, errorMessage } = useSelector((state: RootState) => state.users);
    const { isLoadingSP, errorMessageSP, socialPlatforms } = useSelector((state: RootState) => state.socialPlatforms);
    const [platformValues, setPlatformValues] = useState<Record<string, string>>({});

    useEffect(() => {
        dispatch(fetchAll());
    }, [dispatch]);

    useEffect(() => {
      if (user) {
        const initialValues: Record<string, string> = {};
        user.social_platforms?.forEach((sp) => {
          initialValues[sp.code] = sp.value;
        });
        setPlatformValues(initialValues);
      }
    }, [user]);
    
    const handleConnect = async (code: string) => {
      if (user) {
        try {
          window.location.href = `${process.env.REACT_APP_APP_BASE_URL}/auth/${code}?token=${user.token}`;
        } catch (error) {
          console.error('Failed to redirect user:', error);
        }
      }
    };

    const handleInputChange = (code: string, value: string) => {
      setPlatformValues((prevValues) => ({
        ...prevValues,
        [code]: value,
      }));
    };

    const handleSave = async () => {
      if (user?.id) {
        try {
          const updates = Object.entries(platformValues).map(([code, value]) => ({
            code,
            value,
          }));
    
          const updatedUser = await dispatch(updateSocialPlatform({ userId: user.id, platforms: updates })).unwrap();
          const currentUser = JSON.parse(localStorage.getItem('user') || '{}');
          const mergedUser = { ...currentUser, ...updatedUser };
    
          await dispatch(setUser(mergedUser));
    
          enqueueSnackbar(t('account.messages.updated_success').replace(':attribute', t('account.labels.profile')));
        } catch (error) {
          console.error('Failed to update user:', error);
          enqueueSnackbar(t('account.errors.updated_failed'), { variant: 'error' });
        }
      }
    };

    const isUpdating = isLoading || isLoadingSP;

  return socialPlatforms && (
    <>
      <div className="row">
      {socialPlatforms.data.map((item:SocialPlatform,index:number)=>{
        const value = platformValues[item.code] || '';
        return (
          <div key={index} className="col-12 mb-4">
            <label className="form-label h6" style={{ textTransform: 'capitalize' }}>{item.code}</label>  
            {['twitter', 'instagram'].includes(item.code) ? (
              <>
                <button type="button" className="btn btn-primary rounded-md d-block"
                  disabled={isUpdating}
                  onClick={() => handleConnect(item.code)}
                  >
                  {isUpdating ? t('common.buttons.connect.loading') : t('common.buttons.connect.label')}
                </button>
              </>
            ) : (
              <div className="input-group mb-3">
                <span className="input-group-text">{item.adornment}</span>
                <input
                  type="text"
                  aria-label={item.code}
                  className="form-control"
                  value={value}
                  maxLength={1000}
                  onChange={(e) => handleInputChange(item.code, e.target.value)}
                />
              </div>
            )}
          </div>
        )}
      )}
      </div>
      <div className="row">
          <div className="col-12">
              <button type="button" name="update" className="btn btn-primary rounded-md"
              onClick={handleSave}
              disabled={isUpdating}
              >{t('common.buttons.update.label')}</button>
          </div>
      </div>
    </>
  );
};

export default AccountEditSocialConnectionForm;